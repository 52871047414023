<template>
  <NuxtLink
    :to="disabled ? undefined : url"
    :target="disabled ? undefined : target"
    :aria-label="ariaLabel"
    :event="disabled ? undefined : 'click'"
  >
    <slot></slot>
  </NuxtLink>
</template>

<script setup lang="ts">
type Props = {
  url: string
  ariaLabel?: string
  blank?: boolean
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  url: "",
  ariaLabel: "",
  blank: false,
  disabled: false,
})

// blankがtrueか外部リンクだった場合、新しいタブで開く
const target = computed(() => (props.blank || isExternalLink.value ? "_blank" : ""))

const isExternalLink = computed(() => {
  return props.url.match(/^https?:\/\//) !== null
})
</script>
